import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "form", "couponCode", "roomPriceOption" ]

  connect() {
    console.log(`Conectado a NewBookingController`)
  }

  selectPriceOption(event) {
    const checked = this.roomPriceOptionTargets.find(o => o.checked)
    if (checked && checked.value) {
      console.log(`Selecciona precio`)

      this.disableForm()
      this.loading()

      const parser = new URL(window.location)
      parser.searchParams.set('p', checked.value)
      parser.hash = "summary"
      window.location = parser.href
    }

  }

  applyCoupon(event) {
    event.preventDefault()

    if (this.couponCodeTarget.value) {
      console.log(`Aplica cupón`)

      const parser = new URL(window.location)
      parser.searchParams.set('c', this.couponCodeTarget.value)
      parser.hash = "coupon"
      window.location = parser.href
    }
  }

  disableForm() {
    if (this.formTarget) {
      const fieldset = this.formTarget.querySelector("fieldset")
      if (fieldset) {
        fieldset.disabled = true
      }
    }
  }

  loading() {
    if (this.formTarget) {
      const loading = this.formTarget.querySelector(".loading")
      if (loading) {
        loading.style.display = "block"
      }
    }
  }
}
