var ValidateInput = {
    validateTextInput: function(e) {
        if(e.value){
            $('#'+e.id).addClass('is-valid').removeClass('is-invalid')
            return true
        }else{
            $('#'+e.id).addClass('is-invalid').removeClass('is-valid')
            return false
        }
    },

    validateEmail: function(e) {
        var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if( re.test(e.value) ){
            $('#'+e.id).addClass('is-valid').removeClass('is-invalid')
            return true
        }else{
            $('#'+e.id).addClass('is-invalid').removeClass('is-valid')
            return false
        }
    },

    validateNumber: function(e) {
        !isNaN(e.value) && e.value != ''?
        $('#'+e.id).addClass('is-valid').removeClass('is-invalid') :
        $('#'+e.id).addClass('is-invalid').removeClass('is-valid')
    },
    phoneNumber: function(e) {
        var num = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;

        if(num.test(e.value)){
            $('#'+e.id).addClass('is-valid').removeClass('is-invalid')
            return true
        }else{
            $('#'+e.id).addClass('is-invalid').removeClass('is-valid')
            return false
        }
    },

    validatePassword: function(e) {
        var myInput = $('#'+e.id);
        var letter = $('.letter');
        var capital = $('.capital');
        var number = $('.number');
        var length = $('.length');
        var validate = false;

        myInput.removeClass('is-valid').addClass('is-invalid');

        // Validate lowercase letters
        var lowerCaseLetters = /[a-z]/g;
        if(myInput.val().match(lowerCaseLetters)) {
            letter.removeClass('invalid-feedback').addClass('valid-feedback');
            validate = true;
        } else {
            letter.removeClass('valid-feedback').addClass('invalid-feedback');
            validate = false;
        }

        // Validate capital letters
        var upperCaseLetters = /[A-Z]/g;
        if(myInput.val().match(upperCaseLetters)) {
            capital.removeClass('invalid-feedback').addClass('valid-feedback');
            validate = true;
        } else {
            capital.removeClass('valid-feedback').addClass('invalid-feedback');
            validate = false;
        }

        // Validate numbers
        var numbers = /[0-9]/g;
        if(myInput.val().match(numbers)) {
            number.removeClass('invalid-feedback').addClass('valid-feedback');
            validate = true;
        } else {
            number.removeClass('valid-feedback').addClass('invalid-feedback');
            validate = false;
        }

        // Validate length
        if(myInput.val().length >= 8) {
            length.removeClass('invalid-feedback').addClass('valid-feedback');
            validate = true;
        } else {
            length.removeClass('valid-feedback').addClass('invalid-feedback');
            validate = false;
        }

        if(myInput.val().match(lowerCaseLetters)&&myInput.val().match(upperCaseLetters)&&myInput.val().match(numbers)&&myInput.val().length >= 8){
            myInput.removeClass('is-invalid').addClass('is-valid');
        }
    },

    validateCvv: function(e) {
        var regCVV = /^[0-9]{3,3}$/;

        if(regCVV.test(e.value)){
            $('#'+e.id).addClass('is-valid').removeClass('is-invalid')
            return true
        }else{
            $('#'+e.id).addClass('is-invalid').removeClass('is-valid')
            return false
        }

    },

    validateMMYY: function(e) {
        var pattern = /^(0[1-9]|1[012])\/\d{2}$/;
        if(pattern.test(e.value)){
            $('#'+e.id).addClass('is-valid').removeClass('is-invalid')
            return true
        }else{
            $('#'+e.id).addClass('is-invalid').removeClass('is-valid')
            return false
        }
    }

};

exports.ValidateInput = ValidateInput
