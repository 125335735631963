var lblFrom = document.getElementById('FromPrice');
var lblTo = document.getElementById('ToPrice');

$(document).ready(function() {
    function setLabelPriceValue(data) {
        if(data.from_value === null || data.to_value === null){
            lblFrom.innerHTML = MotelNow.formatNumber.new(50000, '$ ');
            lblTo.innerHTML = MotelNow.formatNumber.new(150000, '$ ');
        }
        else {
            lblFrom.innerHTML = MotelNow.formatNumber.new(data.from_value, '$ ');
            lblTo.innerHTML = MotelNow.formatNumber.new(data.to_value, '$ ');
        }
    }

    //JS Slider
    $('.mn-range-slider').ionRangeSlider({
        type: 'double',
        values: [
            0, 10000, 20000, 30000, 40000, 50000, 60000, 70000, 80000, 90000, 100000,
            110000, 120000, 130000, 140000, 150000, 160000, 170000, 180000, 190000, 200000
        ],
        min: 0,
        max: 180000,
        from: 5,
        to: 15,
        prefix: '$',
        skin: 'round',
        // grid:true,

        onStart: function(data) {
            // Called right after range slider instance initialised
            setLabelPriceValue(data)

            console.log(data.input); // jQuery-link to input
            console.log(data.slider); // jQuery-link to range sliders container
            console.log(data.min); // MIN value
            console.log(data.max); // MAX values
            console.log(data.from); // FROM value
            console.log(data.from_percent + ' %'); // FROM value in percent
            console.log(data.from_value); // FROM index in values array (if used)
            console.log(data.to); // TO value
            console.log(data.to_percent + ' %'); // TO value in percent
            console.log(data.to_value); // TO index in values array (if used)
            console.log(data.min_pretty); // MIN prettified (if used)
            console.log(data.max_pretty); // MAX prettified (if used)
            console.log(data.from_pretty); // FROM prettified (if used)
            console.log(data.to_pretty); // TO prettified (if used)
        },

        onChange: function(data) {
            // Called every time handle position is changed
            setLabelPriceValue(data)
        },

        // onFinish: function(data) {
        //     Called then action is done and mouse is released
        // },

        onUpdate: function(data) {
            // Called then slider is changed using Update public method
        }
    });
    //--JS Slider


});

function clearPrice(e){
    let my_range = $('.mn-range-slider').data('ionRangeSlider');
    lblFrom.innerHTML = MotelNow.formatNumber.new(50000, '$ ');
    lblTo.innerHTML = MotelNow.formatNumber.new(150000, '$ ');
    my_range.reset();
}
