import config from './config.js.erb'
import util from './util.js'
import { ValidateInput } from '../mn/validate-input.js'
import { NewBookingForm } from '../mn/new-booking-form.js'
import Analytics from './analytics.js'

class MotelNow {
}
MotelNow.config = config
MotelNow.util = util
MotelNow.ValidateInput = ValidateInput
MotelNow.NewBookingForm = NewBookingForm
MotelNow.Analytics = Analytics

export default MotelNow
