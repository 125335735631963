(function($) {
    $.fn.incrementr = function(options) {
        var settings = $.extend({
            buttonClass: 'button',
            className: '',
        }, options);

        var updateLabel = function(newValue, label) {
            label.html(newValue)
        }

        return this.each( function() {
            var element = $(this)
            var parent = $('<div class="mn-incrementr">')
            parent.addClass(settings.className)
            var incrementButton = $('<button class="mn-incrementr__control">+</button>')
            var decrementButton = $('<button class="mn-incrementr__control">-</button>')
            var label = $('<span class="mn-incrementr__label">')
            var rightContent = $('<div class="mn-incrementr__right-content">')
            var limit = element.data('limit') || 1000

            element.addClass('mn-incrementr__control')
            element.attr('disabled', true)

            incrementButton.addClass(settings.buttonClass)
            decrementButton.addClass(settings.buttonClass)

            label.append(element.val())

            element.wrap(parent)

            $(element).wrap(rightContent)

            decrementButton.insertBefore(element)
            incrementButton.insertAfter(element)
            element.parent().parent().prepend(label)

            if (!element.val()) {
                element.val(0)
            }

            incrementButton.click(function() {
                var currentValue = parseInt(element.val())
                if ((currentValue + 1) <= limit) {
                    element.val(currentValue + 1)
                    updateLabel(element.val(), label)
                }
            })

            decrementButton.click(function() {
                var currentValue = parseInt(element.val())
                if ((currentValue - 1) >= 0) {
                    element.val(currentValue - 1)
                    updateLabel(element.val(), label)
                }
            })
        });
    }
}(jQuery));
