const conversionStates = ['active']
const reversalStates = ['rejected', 'cancelled_immediately', 'cancelled', 'cancelled_by_office', 'cancelled_after_timeout', 'cancelled_without_payment']


// Guarda el estado de la reserva actual, para evitar duplicaciones
var currentBookingState = null

/**
 * Obtiene el estado actual de reserva
 */
const getCurrentBookingState = function() {
  return currentBookingState
}


/**
 * Setea el estado actual de reserva
 */
const setCurrentBookingState = function(booking) {
  currentBookingState = booking['booking_state']
}


/**
 * Procesa una conversión de reserva.
 * Cuidando de no hacer duplicados.
 */
const processBookingConversion = function(booking, data = {}) {
  const key = `booking_${booking['booking_code']}_conversion_sent`
  if (localStorage.getItem(key) != 'true') {
    localStorage.setItem(key, 'true')

    if (MotelNow.config.production) {
      // GA4
      dataLayer.push({ ecommerce: null })
      dataLayer.push({
        event: "purchase",
        ecommerce: {
          transaction_id: booking['booking_code'],
          affiliation: "MotelNow Web",
          value: booking['booking_commission'],
          tax: "0",
          shipping: "0",
          currency: MotelNow.config.currency,
        }
      })

      // Facebook Pixel
      const fbData = {
        value: booking['booking_commission'],
        currency: MotelNow.config.currency,
        motel: `${booking['booking_motel_id']}-${parameterize(booking['booking_motel_name'])}`,
        room_type: `${booking['booking_room_type_id']}-${parameterize(booking['booking_room_type_name'])}`,
        region_name: data['region_name'],
        locality_name: data['locality_name'],
      }
      const fbEvent = {
        eventID: `bookings/${booking['booking_code']}`,
      }

      fbq('track', 'Purchase', fbData, fbEvent)
      //console.log('track Purchase', fbData, fbEvent)

      sendCrossDomainData('conversion', {
        booking_code: booking.booking_code, booking_price: booking.booking_price
      })
    }
    else {
      console.log(`conversion`)
    }
  }
}


/**
 * Procesa una reversa de reserva.
 */
const processBookingReversal = function(booking, data = {}) {
  const conversionKey = `booking_${booking['booking_code']}_conversion_sent`
  const reversalKey = `booking_${booking['booking_code']}_reversal_sent`
  if (localStorage.getItem(conversionKey) == 'true' && localStorage.getItem(reversalKey) != 'true') {
    localStorage.setItem(reversalKey, 'true')

    if (MotelNow.config.production) {
      // GA4
      dataLayer.push({ ecommerce: null })
      dataLayer.push({
        event: "refund",
        ecommerce: {
          transaction_id: booking['booking_code']
        }
      })

      // Facebook Pixel
      const fbData = {
        value: -1 * booking['booking_commission'],
        currency: MotelNow.config.currency,
        motel: `${booking['booking_motel_id']}-${parameterize(booking['booking_motel_name'])}`,
        room_type: `${booking['booking_room_type_id']}-${parameterize(booking['booking_room_type_name'])}`,
        region_name: data['region_name'],
        locality_name: data['locality_name'],
      }
      const fbEvent = {
        eventID: `bookings/${booking['booking_code']}`,
      }

      // Purchase con valor negativo, para que value calce
      fbq('track', 'Purchase', fbData, fbEvent)
      // Custom event para que las reversiones se cuenten
      fbq('trackCustom', '_PurchaseReversal', fbData, fbEvent)

      //console.log('track _PurchaseReversal', fbData, fbEvent)
      sendCrossDomainData('reversal', {
        booking_code: booking.booking_code, booking_price: booking.booking_price
      })
    }
    else {
      console.log(`reversal`)
    }

  }
}


/**
 * Envía evento por estado de reserva
 */
const sendBookingStateEvent = function(booking, newState) {
  console.log(`state: ${newState}`)
  gtag('event', newState, {
    event_category: 'booking-state',
    event_label: booking['booking_code'],
    value: booking['booking_commission'],
    non_interaction: true,
    booking_code: booking['booking_code']
  })
}


/**
 * Envía un evento customizado
 */
const sendCustomEvent = function(event, data = {}) {
  console.log(`event: ${event}`);

  if (window.fbq) {
    fbq('trackCustom', event, data);
  }

  if (window.gtag) {
    gtag('event', event, data);
  }
}


/**
 * Procesa cambios de estado de reserva.
 * Cuidando de no hacer duplicados.
 */
const processBookingState = function(booking, forcedNewState, data = {}) {
  const oldState = currentBookingState
  const newState = forcedNewState ? forcedNewState : booking['booking_state']

  if (oldState != newState) {

    setCurrentBookingState(booking)
    sendBookingStateEvent(booking, newState)

    if (conversionStates.includes(newState)) {
      processBookingConversion(booking, data)
    }
    else if (reversalStates.includes(newState)) {
      processBookingReversal(booking, data)
    }
  }
}

/**
 * Envía información cross domain.
 * Recibe una etiqueta para indicar el tipo de info
 * y un objeto con datos.
 */
function sendCrossDomainData(tag, data) {
  var payload = {
    tag: tag,
    data: data
  }
  window.parent.postMessage(payload, "*")
}


export default {
  getCurrentBookingState,
  processBookingState,
  sendCustomEvent,
}
